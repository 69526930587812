import { ref, Ref, ComputedRef } from 'vue'
import { useForm } from './useForm'
import { UnwrapNestedRefs } from '@vue/reactivity'

interface ModalFormConfig<FormType extends Record<string, unknown>> {
  formInitialState: FormType
  title: string
  editingTitle?: string | ComputedRef<string>
  editingTarget?: Ref<any> | ComputedRef<any>
  onHideCallback?: () => void
}

export function useModalForm <FormType extends Record<string, unknown>> ({ formInitialState, editingTarget, title, editingTitle, onHideCallback }: ModalFormConfig<FormType>): {
  onShow(): void;
  formValues: UnwrapNestedRefs<FormType>;
  dialogVisible: Ref<boolean>;
  modalTitle: Ref<string>;
  resetForm: () => void;
  patchValues: (newValues: FormType) => void;
  onHide: () => void;
} {
  const form = useForm<FormType>(formInitialState)
  const modalTitle = ref(title)
  const dialogVisible = ref(false)
  return {
    ...form,
    dialogVisible,
    modalTitle,
    onShow () {
      if (!editingTarget?.value) {
        modalTitle.value = title
      } else {
        modalTitle.value = typeof editingTitle === 'string' ? editingTitle : (editingTitle as ComputedRef<string>).value || title
        form.patchValues(editingTarget.value)
      }
    },
    onHide () {
      onHideCallback && onHideCallback()
      form.resetForm()
    }
  }
}
