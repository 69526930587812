
import { computed, defineComponent, PropType, ref } from 'vue'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { TypeModality } from '@/models/academic-definitions/enums'

export default defineComponent({
  props: {
    syllabum: {
      type: Array as PropType<Array<Syllabus>>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    deleteValueAfterSelect: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const selectedSyllabus = ref<Syllabus>()
    const deleteValue = computed(() => props.deleteValueAfterSelect)
    const emmitClick = () => context.emit('click')
    return {
      selectedSyllabus,
      emmitClick,
      TypeModality,
      clickOnVersion: () => {
        context.emit('clickOnVersion', selectedSyllabus.value?.id)
        if (deleteValue.value) {
          selectedSyllabus.value = undefined
        }
      }
    }
  }
})
