import { reactive } from 'vue'
import { UnwrapNestedRefs } from '@vue/reactivity'

interface UseForm <FormType extends Record<string, unknown>> {
  formValues: UnwrapNestedRefs<FormType>
  resetForm: () => void
  patchValues: (newValues: Record<string, unknown>) => void
}

export function useForm <FormType extends Record<string, unknown>> (initialState: FormType): UseForm<FormType> {
  const formValues = reactive<FormType>({ ...initialState })
  function resetForm () {
    Object.assign(formValues, initialState)
  }

  function patchValues (newValues: Record<string, unknown>) {
    // Remueve todas las propiedades que del objeto en edición que no formen parte de las propiedades del formulario...
    const formNewValues = { ...newValues }
    for (const key of Object.keys(formNewValues)) {
      (typeof formValues[key] === 'undefined' || formValues[key] === null) && delete formNewValues[key]
    }
    Object.assign(formValues, formNewValues)
  }

  return {
    formValues,
    resetForm,
    patchValues
  }
}
