
import { useModalForm } from '@/hooks/useModalForm'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import CountrySelector from '@/components/courses/selectors/CountrySelector.vue'
import { SyllabusTarget, TypeModality } from '@/models/academic-definitions/enums'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  components: {
    CountrySelector
  },
  props: {
    defaultFields: {
      required: false,
      type: Object as PropType<Partial<Syllabus>>
    }
  },
  setup (props, context) {
    const defaultF = computed(() => props.defaultFields)
    const { onShow, onHide, modalTitle, formValues, resetForm } = useModalForm({
      formInitialState: {
        clientName: undefined,
        links: {
          folderLink: undefined,
          teacherFolderLink: undefined
        },
        target: undefined,
        modality: {
          type: undefined,
          hoursPerClass: undefined,
          classesPerWeek: undefined
        },
        language: undefined,
        comment: undefined
      },
      title: 'Crear un nuevo programa'
    })
    const validations = {
      modality: {
        type: { required }
      },
      target: { required }
    }
    watch(defaultF, () => {
      if (defaultF.value) {
        Object.assign(formValues, defaultF.value)
        console.log(formValues)
      }
    })
    const v$ = useVuelidate(validations, formValues)
    const submitted = ref<boolean>(false)
    return {
      onShow,
      onHide,
      modalTitle,
      formValues,
      SyllabusTarget,
      TypeModality,
      v$,
      submitted,
      onSubmit: (isFormValid: boolean) => {
        submitted.value = true
        if (!isFormValid) {
          return
        }
        context.emit('submitNewSyllabus', formValues)
        context.emit('update:visible', false)
        resetForm()
      },
      closeModal: () => {
        context.emit('update:visible', false)
        resetForm()
      }
    }
  }
})
