
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { Country } from '@/models/countries/classes/Country'
import { CountriesFlags } from '@/models/countries/enums'
import { Column } from '@/models/components/tables/Column'
import { ColumnFields } from '@/models/components/tables/enums'
import { SyllabusRow } from '@/models/components/tables/Row'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs
} from 'vue'
import { TypeModality } from '@/models/academic-definitions/enums'
import {
  SyllabusWithExpirateDate,
  isExpiring
} from '@/models/academic-definitions/interfaces/composition/SyllabusWithExpirateDate'
import Menu from 'primevue/menu'
import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export default defineComponent({
  props: {
    isExpiringTable: {
      type: Boolean,
      default: false
    },
    syllabum: {
      type: Array as PropType<
        Array<Syllabus> | Array<SyllabusWithExpirateDate>
      >,
      required: true
    }
  },
  emits: ['clickOnVersion', 'newVersion', 'deleteVersion', 'togglePublished'],
  setup (props, { emit }) {
    const { syllabum, isExpiringTable } = toRefs(props)
    const countriesStore = useCountriesStore()
    const countriesActive = computed(() => countriesStore.countries)
    const columns: Column[] = [
      { header: 'Válido Para', field: ColumnFields.Countries },
      { header: 'Tipo', field: ColumnFields.Type },
      { header: 'Versiones', field: ColumnFields.Versions },
      { header: 'Cliente', field: ColumnFields.Client }
    ]
    !isExpiringTable.value
      ? columns.push(
        { header: 'Nueva versión', field: ColumnFields.Duplicate },
        { header: 'URL', field: ColumnFields.Url },
        { header: 'Publicado', field: ColumnFields.Published },
        { header: '', field: ColumnFields.ActionButtons }
      )
      : columns.push(
        { header: 'Expira', field: ColumnFields.ExpiresAt },
        { header: 'URL', field: ColumnFields.Url },
        { header: 'Publicado', field: ColumnFields.Published },
        { header: '', field: ColumnFields.ActionButtons }
      )
    let rows = ref<SyllabusRow[]>([])
    rows = computed<SyllabusRow[]>(() => {
      const rowsFinal: SyllabusRow[] = []
      if (syllabum.value?.length) {
        syllabum.value.map(syllabus => {
          rowsFinal.push({
            id: syllabus.id,
            countries: syllabus.language
              ? (syllabus.language as string[]).length > 0
                  ? countriesActive.value.filter(c =>
                    syllabus.language.includes(c.id)
                  )
                  : countriesActive.value
              : countriesActive.value,
            type: syllabus.modality?.type,
            version: syllabus.version ? syllabus.version : 0,
            clientName: syllabus.clientName ? syllabus.clientName : undefined,
            url: syllabus.links.folderLink,
            published: syllabus.published,
            coursePlanId: syllabus.coursePlan
          })
          return 0
        })
      }
      return rowsFinal
    })
    const menu = ref<ComponentPublicInstance<Menu> | null>()
    const items = ref<MenuItem[]>([
      {
        label: 'Eliminar',
        icon: 'pi pi-trash'
      }
    ])

    const toggle = (event: Event) => {
      menu.value && menu.value.toggle(event)
    }

    return {
      rows,
      EducationRouteNames,
      columns,
      countriesActive,
      ColumnFields,
      Country,
      CountriesFlags,
      TypeModality,
      window,
      toggle,
      items,
      menu,
      clickOnVersion: (syllabusId: string) => {
        emit('clickOnVersion', syllabusId)
      },
      emitNewVersion: (rowData: SyllabusRow) => {
        const syllabus = syllabum.value.find(s => s.id === rowData.id)
        emit('newVersion', syllabus)
      },
      deleteVersion: (syllabusId: string, event: Event) => {
        toggle(event)
        emit('deleteVersion', syllabusId)
      },
      emitTogglePublished: (syllabusId: string) =>
        emit('togglePublished', syllabusId),
      isExpiring
    }
  }
})
