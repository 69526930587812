import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    disabled: _ctx.disabled,
    options: _ctx.syllabum,
    placeholder: _ctx.placeholder,
    modelValue: _ctx.selectedSyllabus,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedSyllabus = $event)),
    onClick: _ctx.emmitClick,
    onChange: _ctx.clickOnVersion,
    loading: _ctx.loading,
    emptyMessage: "No hay programas"
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, "Versión " + _toDisplayString(slotProps.value.version) + " - " + _toDisplayString(slotProps.value.clientName
          ? slotProps.value.clientName
          : slotProps.value.modality?.type === _ctx.TypeModality.intensive
            ? 'Intensivo'
            : 'Regular'), 1)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.placeholder), 1))
    ]),
    option: _withCtx((slotProps) => [
      _createElementVNode("span", null, "Versión " + _toDisplayString(slotProps.option.version) + " - " + _toDisplayString(slotProps.option.clientName
        ? slotProps.option.clientName
        : slotProps.option.modality?.type === _ctx.TypeModality.intensive
            ? 'Intensivo'
            : 'Regular'), 1)
    ]),
    _: 1
  }, 8, ["disabled", "options", "placeholder", "modelValue", "onClick", "onChange", "loading"]))
}