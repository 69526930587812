
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import SyllabumTable from '../../components/SyllabumTable.vue'
import NewSyllabusModal from '../../components/NewSyllabusModal.vue'
import SyllabusSelector from '@/components/educationPlans/selectors/SyllabusSelector.vue'
import { SyllabusWithExpirateDate } from '@/models/academic-definitions/interfaces/composition/SyllabusWithExpirateDate'
import { useRoute, useRouter } from 'vue-router'
import { EducationPlan } from '@/models/academic-definitions/classes/EducationPlan'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { SyllabusTarget, TypeModality } from '@/models/academic-definitions/enums'
import { useCountriesStore } from '@/store/modules/commons/countries-store'
import { useToast } from 'primevue/usetoast'
import { ToastSeverities } from '@/models/components/toast/enums'
import { useConfirm } from 'primevue/useconfirm'
import { SyllabusAPI } from '@/api/academic-definitions/syllabus.api'
import { EducationRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    SyllabumTable,
    NewSyllabusModal,
    SyllabusSelector
  },
  props: {
    educationPlan: {
      type: Object as PropType<EducationPlan>
    },
    coursePlans: {
      type: Array as PropType<CoursePlan[]>
    }
  },
  setup () {
    const toast = useToast()
    const confirm = useConfirm()
    const router = useRouter()
    const syllabumLastVersions = ref<Syllabus[]>([])
    const syllabumExpiring = ref<SyllabusWithExpirateDate[]>([])
    const syllabumArchived = ref<Syllabus[]>([])
    const allSyllabum = computed(() => syllabumLastVersions.value.concat(syllabumExpiring.value, syllabumArchived.value))
    const loadingArchived = ref<boolean>(false)
    const { params: { planId } } = useRoute()
    const loadingSyllabum = ref(false)
    const displayNewSyllabusModal = ref(false)
    const countriesActive = computed(() => useCountriesStore().countries)
    const syllabusLastVersionCreated = ref<Partial<Syllabus>>()
    const updateTables = async () => {
      try {
        loadingSyllabum.value = true
        const syllabumLastVersionsPromise = SyllabusAPI.findLastVersions(planId as string)
        const syllabumActivePromise = SyllabusAPI.findActiveVersions(planId as string)
        syllabumLastVersions.value = await syllabumLastVersionsPromise
        syllabumExpiring.value = await syllabumActivePromise
      } catch (error) {
        console.log(error)
      } finally {
        loadingSyllabum.value = false
      }
    }

    onMounted(updateTables)

    const fetchArchivedSyllabum = async () => {
      try {
        loadingArchived.value = true
        const syllabumArchivedPromise = SyllabusAPI.findArchivedVersions(planId as string)
        syllabumArchived.value = await syllabumArchivedPromise
        loadingArchived.value = false
      } catch (e) {
        console.error(e)
      }
    }

    const assignVersionAndCreate = async (s: Partial<Syllabus>) => {
      const syllabusDTO = { ...s }
      syllabusDTO.coursePlan = planId as string // le asignamos el curso
      if (!syllabusDTO.language || syllabusDTO.language?.length === 0) {
        syllabusDTO.language = countriesActive.value.map(c => c.id)
        // Si no especifica país, le agrega todos los países
      }
      if (syllabusDTO.modality) {
        syllabusDTO.modality.classesPerWeek = (() => {
          return syllabusDTO.modality.type
            ? syllabusDTO.modality.type === TypeModality.intensive
              ? 1
              : 2
            : 2
        })()
      }
      syllabusDTO.clientName
        ? syllabusDTO.version = (((syllabumLastVersions.value as Syllabus[]).find(s => s.clientName === syllabusDTO.clientName)) as Syllabus)?.version + 1 || 1
        : syllabusDTO.modality?.type === TypeModality.intensive
          ? syllabusDTO.version = ((syllabumLastVersions.value as Syllabus[]).find(s => s.modality.type === TypeModality.intensive && s.target === SyllabusTarget.b2c)?.version as number || 0) + 1
          : syllabusDTO.version = ((syllabumLastVersions.value as Syllabus[]).find(s => s.modality.type === TypeModality.regular && s.target === SyllabusTarget.b2c)?.version as number || 0) + 1
      console.log(syllabusDTO.version)
      try {
        await SyllabusAPI.create(syllabusDTO)
        await updateTables()
      } catch (e) {
        console.error(e)
      }
    }

    const openNewSyllabusModalWithDefaultFields = (s: Syllabus) => {
      syllabusLastVersionCreated.value = { ...s }
      displayNewSyllabusModal.value = !displayNewSyllabusModal.value
    }

    const showConfirmDeleteVersion = (syllabusId: string) => {
      confirm.require({
        message: '¿Estás seguro/a de eliminar este programa?',
        header: '¡Alto ahí!',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          deleteVersionOrRejectDelete(syllabusId)
        },
        reject: () => {
          confirm.close()
        }
      })
    }
    const deleteVersionOrRejectDelete = async (syllabusId: string) => {
      if (syllabumExpiring.value.every(s => s.id !== syllabusId)) {
        try {
          await SyllabusAPI.delete(syllabusId)
          await updateTables()
          toast.add({
            severity: ToastSeverities.Success,
            detail: '¡Programa eliminado!',
            life: 1500
          })
        } catch (e) {
          console.error(e)
        }
      } else {
        toast.add({
          severity: ToastSeverities.Error,
          detail: 'No se puede eliminar un programa que está en uso',
          life: 1500
        })
      }
    }
    const togglePublished = async (syllabusId: string) => {
      try {
        await SyllabusAPI.togglePublished(syllabusId)
        await updateTables()
        toast.add({
          severity: ToastSeverities.Success,
          detail: '¡Programa actualizado!',
          life: 1500
        })
      } catch (e) {
        console.error(e)
        toast.add({
          severity: ToastSeverities.Error,
          detail: '¡Error al actualizar programa!',
          life: 1500
        })
      }
    }
    return {
      syllabumLastVersions,
      syllabumExpiring,
      syllabumArchived,
      syllabusLastVersionCreated,
      loadingSyllabum,
      loadingArchived,
      fetchArchivedSyllabum,
      displayNewSyllabusModal,
      planId,
      assignVersionAndCreate,
      openNewSyllabusModalWithDefaultFields,
      showConfirmDeleteVersion,
      goToSyllabusDetail: (syllabusId: string) => {
        const s = allSyllabum.value.find(s => s.id === syllabusId)
        router.push({
          name: EducationRouteNames.CONTENT_COURSES_DETAIL_SYLLABUM_DETAIL,
          params: {
            planId: s?.coursePlan,
            syllabusId: s?.id
          }
        })
      },
      togglePublished
    }
  }
})
